<script lang="ts">
import { defineAsyncComponent } from "vue";

import {
  generatePlatformData,
  addPageItemArray,
  dataKeys,
  getInputPattern,
  getInputType,
  generatePlaceholder,
  generateInputTitle,
  processUserInput,
} from "@/helpers";

import { useBusinessPagesStore, useAccountStore, useAuthStore } from "@/stores";

import EditPageDataPopup from "@/components/Popup/page/EditPageDataPopup.vue";

import PageItemContainer from "@/components/List/ListContainer/PageItemContainer.vue";
import PrivatePageListItem from "@/components/List/ListItem/PrivatePageListItem.vue";
import InputWrapper from "@/components/Input/InputWrapper.vue";

import { useFavicon } from "@vueuse/core";

export default {
  components: {
    PageItemContainer,
    PrivatePageListItem,
    InputWrapper,
    EditPageDataPopup,
    draggable: defineAsyncComponent(() => import("vuedraggable")),
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
  },
  data() {
    return {
      selectedAddressVariable: "",
      pageVariables: [],
      addPageItemArray,
      accountStore: useAccountStore(),
      businessPagesStore: useBusinessPagesStore(),
      authStore: useAuthStore(),
      isEditDialogVisible: false,
      isAddItemDialogVisible: false,
      isAddItemSubDialogVisible: false,
      isAddItemSubDialogAddressVisible: false,
      selectedPageItem: {
        itemName: "",
        dynamicField: "",
      },
      pageItemData: {
        itemPosition: 0,
        itemType: "",
        itemName: "",
        itemData: "",
      },
      address: {
        street: "",
        number: "",
        zipcode: "",
        city: "",
        // state: "",
        country: "",
      },
      drag: false,
      icon: useFavicon(),
      isPageDataLoaded: true,
      arePageItemsLoaded: true,
      businessId: "",
    };
  },
  computed: {
    dynamicFieldLabel() {
      return (
        this.selectedPageItem.dynamicField.charAt(0).toUpperCase() +
        this.selectedPageItem.dynamicField.slice(1)
      );
    },
    computedLinkUrl() {
      return (itemType: string, itemData: any) => {
        const { linkUrl } = generatePlatformData(itemType, itemData);
        return linkUrl;
      };
    },
    computedInputType() {
      return (itemType: string) => {
        return getInputType(itemType);
      };
    },
    computedInputPattern() {
      return (itemType: string) => {
        return getInputPattern(itemType);
      };
    },
    computedInputPlaceholder() {
      return (itemType: string) => {
        return generatePlaceholder(itemType);
      };
    },
    computedInputTitle() {
      return (itemType: string) => {
        return generateInputTitle(itemType);
      };
    },
    computedImageUrl() {
      return (itemType: string) =>
        `https://cdn.connectflow.app/prod/items/${itemType}.svg`;
    },
    selectedVariableKeyDisplay() {
      return `{${this.selectedAddressVariable.variablekey}}`;
    },
  },
  created() {
    this.getAccountInformation();

    this.getPageTemplateItems().then(() => {
      this.arePageItemsLoaded = true;
    });

    this.getPageVariables();
  },
  mounted() {
    if (!this.accountStore.isUserAdmin) {
      this.$router.push("/");
    }
  },
  methods: {
    async getPageVariables() {
      const pageVariables = await this.businessPagesStore.getPageVariables(
        this.businessId
      );

      this.pageVariables = pageVariables;
    },
    async getAccountInformation() {
      const userInfo = this.accountStore.user;

      if (!userInfo) {
        this.authStore.logout();
        return;
      }

      if (!this.businessId) {
        this.businessId = userInfo.belongToBusinessObjId;
      }
    },
    onCloseEditDialog(isEditDialogVisible: boolean) {
      // Handle the emitted value here, if needed
      this.isEditDialogVisible = isEditDialogVisible;
      this.pageItemData.itemData = "";
    },
    async showSubAddItemDialog(selectedPageItem: any) {
      this.isAddItemSubDialogAddressVisible =
        selectedPageItem.title === "Address" ? true : false;

      let pageItemsCount;

      await this.businessPagesStore
        .getPageTemplateItems(this.businessId)
        .then((data) => {
          pageItemsCount = data.count;
        })
        .catch((error) => {
          pageItemsCount = 0;
        });

      this.pageItemData.itemName = selectedPageItem.title;
      this.pageItemData.itemType = selectedPageItem.slug;
      this.pageItemData.itemPosition = pageItemsCount;

      this.selectedPageItem.dynamicField =
        dataKeys[selectedPageItem.title] || "username";
      this.isAddItemDialogVisible = false;
      this.isAddItemSubDialogVisible = true;
    },
    async getPageTemplateItems() {
      await this.businessPagesStore.getPageTemplateItems(this.businessId);
      // .then()
      // .catch((error) => {
      //   const noPageItemsOnPage = error === "Page Items are not found";

      //   if (noPageItemsOnPage) {
      //     this.pagesStore.setPageItems([]);
      //   }
      // });
    },
    async createPageItem() {
      this.pageItemData.itemData = processUserInput(
        this.pageItemData.itemData,
        this.pageItemData.itemType
      );

      await this.businessPagesStore
        ?.createPageTemplateItem(this.businessId, this.pageItemData)
        .then(() => {
          this.isAddItemDialogVisible = false;
          this.isAddItemSubDialogVisible = false;
          this.isAddItemSubDialogAddressVisible = false;
          this.getPageTemplateItems();
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Page is updated",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
      this.pageItemData.itemData = "";
    },
    handleItemUpdate(updatedItem) {
      this.pageItemData.itemName = updatedItem.itemName;
      this.pageItemData.itemData = updatedItem.itemData;
    },
    async handleAddressSubmit() {
      let addressString: string;

      if (this.selectedAddressVariable) {
        addressString = this.selectedVariableKeyDisplay;
      } else {
        addressString = `${this.address.street};${this.address.number};${this.address.zipcode};${this.address.city};${this.address.country}`;
      }

      const addressData = {
        itemPosition: this.pageItemData.itemPosition,
        itemType: "address",
        itemName: this.pageItemData.itemName,
        itemData: addressString,
      };

      await this.businessPagesStore
        ?.createPageTemplateItem(this.businessId, addressData)
        .then(() => {
          this.isAddItemDialogVisible = false;
          this.isAddItemSubDialogVisible = false;
          this.isAddItemSubDialogAddressVisible = false;
          this.getPageTemplateItems();
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Page is updated",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
      this.pageItemData.itemData = "";
    },
    async updatePageItemsPosition(event) {
      this.drag = false;

      const oldPosition = event.oldIndex;
      const newPosition = event.newIndex;

      if (newPosition === oldPosition) {
        return;
      }

      if (!this.businessPagesStore.pageTemplateItems) {
        return;
      }

      try {
        this.businessPagesStore.pageTemplateItems.map(async (item, index) => {
          await this.businessPagesStore.updatePageTemplateItem(
            this.businessId,
            item.itemId,
            {
              itemPosition: index,
            }
          );
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Failed to update positions: ${error}`,
          life: 5000,
        });
      }
    },
    // Limitations: only one variable is possible right now
    addVariableToInput(variableName: string) {
      const regex = /\{.*?\}/;
      let newPageItemData;

      if (regex.test(this.pageItemData.itemData)) {
        newPageItemData = this.pageItemData.itemData.replace(
          regex,
          `{${variableName}}`
        );
      } else {
        newPageItemData = `${this.pageItemData.itemData}{${variableName}}`;
      }

      this.pageItemData.itemData = newPageItemData;
    },
  },
  watch: {
    isAddItemSubDialogVisible() {
      if (!this.isAddItemSubDialogVisible) {
        this.pageItemData.itemData = "";
      }
    },
  },
};
</script>

<template>
  <!-- <VueHead v-if="isPageDataLoaded">
    <title>
      {{
        `${currentPage?.fname} ${currentPage?.lname}  • ${currentPage?.company} - ConnectFlow`
      }}
    </title>
    <meta
      name="description"
      :content="`${currentPage?.fname} ${currentPage?.lname}  • ${currentPage?.company} - ConnectFlow`"
    />
  </VueHead> -->

  <EditPageDataPopup
    v-if="isEditDialogVisible"
    @close-edit-dialog="onCloseEditDialog"
    :visible="isEditDialogVisible"
  />

  <div class="container">
    <!-- User information -->
    <section class="user-info edit">
      <div class="avatar"></div>

   
      <h1>{firstname} {lastname}</h1>
      <h2>{jobtitle} &bull; {company}</h2>
      <p>{bio}</p>

    </section>

    <!-- Page Items List -->
    <main>
      <PageItemContainer title="">
        <PVSkeleton
          v-if="!this.arePageItemsLoaded"
          width="100%"
          height="320px"
        />
        <draggable
          class="inner-container edit"
          v-model="this.businessPagesStore.pageTemplateItems"
          group="people"
          ghost-class="ghost"
          @start="drag = true"
          @end="updatePageItemsPosition"
          item-key="id"
          handle=".handle"
        >
          <template #item="{ element }">
            <PrivatePageListItem
              :itemId="element.itemId"
              :itemData="element.itemData"
              :itemType="element.itemType"
              :itemName="element.itemName"
              :link="computedLinkUrl(element.itemType, element.itemData)"
              avatar="url"
              :newTab="true"
              :isEditing="true"
              :imageUrl="computedImageUrl(element.itemType)"
              :isBusiness="true"
              :businessId="this.businessId"
              @update:item="handleItemUpdate"
            />
          </template>
        </draggable>
      </PageItemContainer>

      <PVButton
        class="btn btn-primary add"
        label="Add item"
        icon="pi pi-plus"
        @click="isAddItemDialogVisible = true"
      />
    </main>
  </div>

  <!-- AddPageItemSubPopup -->
  <PVPopup
    v-if="!isAddItemSubDialogAddressVisible"
    v-model:visible="isAddItemSubDialogVisible"
    modal
    dismissableMask
    :draggable="false"
    :header="selectedPageItem.itemName ?? 'Header'"
  >
    <form
      class="input-container"
      id="add-item-form"
      @submit.prevent="createPageItem"
    >
      <InputWrapper
        labelText="Title"
        labelRef="itemTitle"
        v-model="pageItemData.itemName"
        variant="grey"
        isRequired
      />

      <InputWrapper
        :labelText="selectedPageItem.dynamicField"
        labelRef="itemData"
        v-model="pageItemData.itemData"
        variant="grey"
        :placeholder="computedInputPlaceholder(pageItemData.itemType)"
        isRequired
      />
    </form>

    <ul class="variables-container">
      <li v-for="(variable, index) in this.pageVariables" :key="index">
        <button @click="addVariableToInput(variable.variablekey)">
          {{ variable.variableName }}
        </button>
      </li>
    </ul>

    <template #footer>
      <div class="btn-container">
        <PVButton
          label="Go back"
          class="btn btn-secondary"
          icon="pi pi-chevron-left"
          @click="
            isAddItemDialogVisible = true;
            isAddItemSubDialogVisible = false;
          "
        />
        <PVButton
          label="Save"
          class="btn btn-primary"
          autofocus
          type="submit"
          form="add-item-form"
        />
      </div>
    </template>
  </PVPopup>

  <!-- AddPageItemAddressPopup -->
  <PVPopup
    v-if="isAddItemSubDialogAddressVisible"
    v-model:visible="isAddItemSubDialogVisible"
    modal
    :dismissableMask="true"
    :draggable="false"
    :header="selectedPageItem.itemName ?? 'Header'"
  >
    <form
      class="input-container"
      id="add-address-form"
      @submit.prevent="handleAddressSubmit"
    >
      <InputWrapper
        labelText="Title"
        labelRef="itemTitle"
        v-model="pageItemData.itemName"
        variant="grey"
        isRequired
      />

      <InputWrapper
        v-if="!this.selectedAddressVariable"
        labelText="Street"
        labelRef="street"
        v-model="address.street"
        variant="grey"
        isRequired
      />

      <InputWrapper
        v-if="!this.selectedAddressVariable"
        labelText="Number"
        labelRef="number"
        v-model="address.number"
        variant="grey"
        isRequired
      />

      <InputWrapper
        v-if="!this.selectedAddressVariable"
        labelText="Zipcode"
        labelRef="zipcode"
        v-model="address.zipcode"
        variant="grey"
        isRequired
      />

      <InputWrapper
        v-if="!this.selectedAddressVariable"
        labelText="City"
        labelRef="city"
        v-model="address.city"
        variant="grey"
        isRequired
      />
      <!-- <InputWrapper
        labelText="State"
        labelRef="state"
        v-model="address.state"
        variant="grey"
      /> -->
      <InputWrapper
        v-if="!this.selectedAddressVariable"
        labelText="Country"
        labelRef="country"
        v-model="address.country"
        variant="grey"
        isRequired
      />

      <InputWrapper
        v-if="this.selectedAddressVariable"
        labelText="Field Name"
        labelRef="field"
        v-model="selectedVariableKeyDisplay"
        variant="grey"
        isRequired
      />
    </form>

    <div class="address-variable-container">
      <p>Select field</p>
      <PVDropdown
        v-model="this.selectedAddressVariable"
        editable
        :options="this.pageVariables"
        optionLabel="variableName"
        placeholder="Select"
      />
    </div>

    <div class="remove-container" v-if="this.selectedAddressVariable">
      <PVButton
        icon="pi pi-undo"
        aria-label="Close page"
        class="btn remove-btn"
        @click="this.selectedAddressVariable = ''"
      />
      <p>Undo selected field</p>
    </div>

    <template #footer>
      <div class="btn-container">
        <PVButton
          label="Go back"
          class="btn btn-secondary"
          icon="pi pi-chevron-left"
          @click="
            this.isAddItemDialogVisible = true;
            this.isAddItemSubDialogVisible = false;
            this.isAddItemSubDialogAddressVisible = false;
          "
        />
        <PVButton
          label="Save"
          class="btn btn-primary"
          autofocus
          type="submit"
          form="add-address-form"
        />
      </div>
    </template>
  </PVPopup>

  <!-- AddPageItemPopup -->
  <PVPopup
    v-model:visible="isAddItemDialogVisible"
    modal
    dismissableMask
    :draggable="false"
    header="Add item"
  >
    <ul>
      <li v-for="item in addPageItemArray" :key="item.id">
        <button class="btn-no-style" @click="showSubAddItemDialog(item)">
          <article>
            <img
              height="30"
              width="30"
              :src="`https://cdn.connectflow.app/prod/items/${item.slug}.svg`"
            />
            <h1>{{ item.title }}</h1>
            <h2>{{ item.subtitle }}</h2>
          </article>
        </button>
      </li>
    </ul>

    <template #footer>
      <PVButton
        label="Cancel"
        icon="pi pi-times"
        class="btn btn-secondary"
        @click="isAddItemDialogVisible = false"
        text
      />
    </template>
  </PVPopup>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/shared/views/page.css";

.user-info {
  max-width: 100%;
}

.container {
  .btn.btn-primary.add {
    margin-top: 25px;
    margin-bottom: 50px;
    width: auto;
    border-radius: 50px;
    animation: move-up-50 0.6s ease 0s 1 normal;
  }

  .btn-container {
    display: flex;
  }
}

ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto auto;
  grid-gap: 12px;
  list-style: none;
  padding-left: 0;
  padding-top: 8px;

  button {
    width: 100%;
    text-align: left;
    transition: transform 0.2s;
  }
}
article {
  background-color: var(--color-input-background-grey);
  /* border: 1px solid var(--color-grey); */
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  transition: 0.3s;

  &:hover {
    transform: scale(1.01);
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    background-color: white;
    transition: 0.3s;
  }

  &:active {
    transform: scale(0.99);
    transition: 0.3s;
    background-color: var(--color-input-background-grey);
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0));
  }

  img {
    height: 32px;
    width: 32px;
    border-radius: 6px;
    margin-bottom: 12px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  }

  h1 {
    font-size: 1.1em;
    font-weight: 600;
    line-height: initial;
    text-align: left;
    padding: 0;
  }

  h2 {
    font-size: 0.9em;
    color: #22222280;
    line-height: initial;
    font-weight: 600;
    margin: 0;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.full-width input {
  width: 100%;
  color: var(--color-black);
  font-weight: 500;
  background-color: var(--color-input-background-grey);
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ghost {
  opacity: 0.5;
  background-color: var(--color-background);
  border-radius: 12px;
  animation: zoom-in-zoom-out 1s ease infinite;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}

.editing {
  padding: 20px;
}

.btn.btn-primary.add {
  margin-top: 25px;
  margin-bottom: 50px;
}

.btn-container {
  display: flex;
}

.add-variable-container {
  position: absolute;
  top: 0;
  right: 24px;
}

.variables-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-weight: 600;

  li {
    font-weight: 600;
    transition: 0.2s;

    button {
      background-color: var(--color-input-background-grey);
      font-family: var(--font-primary);
      padding: 8px 12px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      font-size: 16px;
      font-weight: 600 !important;
      font-weight: normal;
      transition: 0.2 !important;

      &:hover {
        transform: scale(1.01);
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
        background-color: white;
        transition: 0.2s;
      }
    }
  }
}

.address-variable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 12px;
}
</style>
