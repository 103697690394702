<script lang="ts">
import { useAccountStore, useBusinessSettingsStore } from "@/stores";

export default {
    data() {
        return {
            showComnapyNameOnSameLineButton: true,
            showComnapyNameOnNextLineButton: false,
            accountStore: useAccountStore(),
            businessSettingsStore: useBusinessSettingsStore(),
            businessId: "",
            updatedSettings: {},
        };
    },
    created() {
        this.businessId = this.accountStore.user.belongToBusinessObjId;
        this.setInitialContent();
    },
    methods: {
        // async updateButtonSetting(property: string, labelName: string) {
        //   const value = this[property];
        //   const updatedSetting: { [key: string]: any } = {};
        //   updatedSetting[property] = value;

        //   this.businessSettingsStore
        //     .setBusinessSettings(this.businessId, updatedSetting)
        //     .then(() => {
        //       this.$toast.add({
        //         severity: "success",
        //         summary: "Updated",
        //         detail: `${labelName} is updated.`,
        //         life: 5000,
        //       });
        //     });
        // },

        async setInitialContent() {
            const businessSettings =
                await this.businessSettingsStore.getBusinessSettings(this.businessId);
            this.showComnapyNameOnSameLineButton = businessSettings.showComnapyNameOnSameLineButton;
            this.showComnapyNameOnNextLineButton = businessSettings.showComnapyNameOnNextLineButton;
        },

        handleCheckboxChange(selectedProperty: string, otherProperty: string, labelName: string) {
            // Set the selected checkbox to true
            this[selectedProperty] = true;

            // Set the other checkbox to false
            this[otherProperty] = false;

            // Update both settings
            const updatedSettings: { [key: string]: boolean } = {
                [selectedProperty]: true,
                [otherProperty]: false,
            };

            this.businessSettingsStore
                .setBusinessSettings(this.businessId, updatedSettings)
                .then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Updated",
                        detail: `${labelName} is updated.`,
                        life: 5000,
                    });
                });
        },
    },
};
</script>

<template>
    <div>
        <div class="content-card">
            <input id="showComnapyNameOnSameLineButton" name="Separate by •" type="checkbox"
                :checked="showComnapyNameOnSameLineButton"
                @change="handleCheckboxChange('showComnapyNameOnSameLineButton', 'showComnapyNameOnNextLineButton', 'Separate by •')" />
            <label for="showComnapyNameOnSameLineButton">Separate by •</label>
        </div>
        <div class="content-card">
            <input type="checkbox" id="showComnapyNameOnNextLineButton" name="Separate on new line"
                :checked="showComnapyNameOnNextLineButton"
                @change="handleCheckboxChange('showComnapyNameOnNextLineButton', 'showComnapyNameOnSameLineButton', 'Separate on new line')" />
            <label for="showComnapyNameOnNextLineButton" class="title">Separate on new line</label>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.content-card {
    display: flex;
    flex-direction: row;
    background-color: var(--color-white);
    border-radius: 6px;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;
}

.text p {
    margin: 0;
    line-height: 1.25em;
}

label {
    font-size: 17.5px;
    font-weight: 600;
    color: #222;
}
</style>