<script>
import DNSCompanySetting from "@/components/business/settings/DNSCompanySetting.vue";
import StylingCompanySetting from "@/components/business/settings/StylingCompanySetting.vue";
import GeneralCompanySetting from "@/components/business/settings/GeneralCompanySetting.vue";
import VirtualCardSetting from "@/components/business/settings/VirtualCardSetting.vue";
import ContentSetting from "@/components/business/settings/ContentSetting.vue";
import CompanyNameToggleSetting from "@/components/business/settings/CompanyNameToggleSetting.vue";


import { useBusinessSettingsStore, useAccountStore } from "@/stores";

export default {
  data() {
    return {
      businessSettingsStore: useBusinessSettingsStore(),
      accountStore: useAccountStore(),
      businessId: "",
    };
  },
  created() {
    this.businessId = this.accountStore.user.belongToBusinessObjId;
  },
  mounted() {
    if (!this.accountStore.isUserAdmin) {
      this.$router.push("/");
    }
  },
  components: {
    DNSCompanySetting,
    StylingCompanySetting,
    GeneralCompanySetting,
    VirtualCardSetting,
    ContentSetting,
    CompanyNameToggleSetting
  },
};
</script>

<template>
  <div class="business-settings-container">
    <div class="left">
      <section class="styling">
        <h1>Styling</h1>
        <StylingCompanySetting />
      </section>
    </div>

    <div class="right">
      <section class="company">
        <h1>Company</h1>
        <GeneralCompanySetting />
      </section>
      <section>
        <h1>Content</h1>
        <ContentSetting />
      </section>
      <section>
        <h1>Job Title and Company</h1>
        <CompanyNameToggleSetting />
      </section>
      <section class="virtual-card">
        <h1>Virtual Card</h1>
        <VirtualCardSetting :businessId="this.businessId" />
      </section>
      <section v-if="businessSettingsStore.businessSettings.subdomain">
        <h1>DNS Instructions</h1>
        <DNSCompanySetting />
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.business-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px !important;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 487px) {
    padding: 32px 24px;
  }
}

.right,
.left {
  flex-grow: 1;
}

section {
  background-color: var(--color-white);
  border-radius: 6px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;

  h1 {
    color: var(--color-text-grey);
    top: -24px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    left: 0;
  }
}

section.company {
  background-color: transparent;
  gap: 8px;
}

section.styling {
  padding: 10px 18px;
}

section.virtual-card {
  padding: 14px;
  margin-top: 40px;
}
</style>
